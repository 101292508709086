export const firebaseAppName = 'loopable-co'

export const firebaseConfig = {
  apiKey: 'AIzaSyC0cP1mhWJ1PPp5tj9iri8Jzm1DzAOtMiI',
  authDomain: `${firebaseAppName}.firebaseapp.com`,
  projectId: firebaseAppName,
  storageBucket: `${firebaseAppName}.appspot.com`,
  messagingSenderId: '87966193720',
  appId: '1:87966193720:web:7ac33846bb0af71a4aeecd',
}
