import Router from 'next/router'
import { Route } from '../types/route'

import type { Timestamp } from 'firebase-admin/firestore'

import type { ImageMeta } from '../types/organization'

// Synced with app.loopable.co repo
export const AssessmentGuaranteeDays = 90

export const ResponseDays = 7

export const EasiestCoursesOrgname = 'easiestcourses'

export const LocalDomain = 'localhost'
export const DefaultDomain = 'app.easiestcourses.com'

export const DefaultLogoDisplayWidth = 150

const TestDomains = ['staging.loopable.co']

export const canonicalAppDomain = (domain: string): string => {
  let appDomain: string

  if (domain === LocalDomain || TestDomains.includes(domain)) {
    appDomain = DefaultDomain
  } else {
    appDomain = domain
  }

  return appDomain
}

export const imageMetaUrl = (meta: ImageMeta | undefined): string | undefined => {
  if (!meta) {
    return
  }
  const url = new URL(meta.url)
  url.searchParams.append('v', meta.version.toString())
  return url.toString()
}

export const isExpired = (expires: Timestamp): boolean => {
  const now = new Date()
  const expiresDate = expires.toDate()
  return now > expiresDate
}

export const redirect = async (route: Route) => {
  // Avoid infinite redirects
  if (window.location.pathname === route) {
    return
  }

  // Shallow update, otherwise infinite loop occurs
  await Router.replace(route, undefined, { shallow: true })
}

// Mainly used for query parameters which can be non-unique (e.g. x=1&x=2)
export const first = (param: string | string[] | undefined) => {
  if (param === undefined) {
    return undefined
  }
  if (Array.isArray(param)) {
    if (param.length) {
      return param[0]
    } else {
      return undefined
    }
  }
  return param
}

type FormattedProgressOpt = {
  progress: number
  complete: boolean
}

export const formattedProgress = ({
  progress,
  complete,
}: FormattedProgressOpt) => {
  const percent = Math.round(100 * progress)
  const almost = percent === 100 && !complete
  return almost ? '99%' : `${percent}%`
}
