import { useState, Dispatch, SetStateAction, useEffect } from 'react'

import { initializeApp } from 'firebase/app'
import { Firestore, getFirestore, query, where, limit, getDocs, collection } from 'firebase/firestore'
import { getAuth, onAuthStateChanged, Auth, User } from 'firebase/auth'
import type { FirebaseApp } from 'firebase/app'

import type { App, AuthStatus, Session } from '../../types/app'
import type { OrganizationPublic } from '../../types/organization'
import useMountEffect from './mounteffect'

import projectEnv from '../../lib/env'
import { canonicalAppDomain } from '../utils'

import { loopableAdminClaimKey } from '../../lib/admin'

import { firebaseAppName, firebaseConfig } from '../firebaseConfig'

const useApp = (): [
  App | undefined,
  Dispatch<SetStateAction<App | undefined>>,
] => {
  const [app, setApp] = useState<App>()

  const [auth, setAuth] = useState<Auth>()
  const [db, setDb] = useState<Firestore>()
  const [firApp, setFirApp] = useState<FirebaseApp>()

  const [user, setUser] = useState<User | null>()

  const [authStatus, setAuthStatus] = useState<AuthStatus>()
  const [session, setSession] = useState<Session>()
  const [org, setOrg] = useState<OrganizationPublic>()

  // When authStatus and org are set, initialize app
  useEffect(() => {
    if (!auth || !db || !firApp || !authStatus || !org) {
      return
    }
    setApp({ db, auth, firApp, status: authStatus, org, session })
  }, [auth, db, firApp, authStatus, session, org])

  // On sign-in and sign-out, update authStatus and session
  useEffect(() => {
    // undefined means no status. null means signed out.
    if (user === undefined || org === undefined) {
      return
    }

    // Signed out

    if (user === null) {
      console.log('User signed out')
      setAuthStatus('signedOut')
      setSession(undefined)
      return
    }

    // Signed in

    const { uid, email } = user
    if (!email) {
      console.error(`No email for user ${uid}`)
      return
    }

    const getClaims = async () => {
      const token = await user.getIdTokenResult()

      const loopableAdmin = !!token.claims[loopableAdminClaimKey]
      const orgAdmin = token.claims.adminOrganizationId === org.id
      const admin = (loopableAdmin || orgAdmin)

      console.log(`${email} signed in${admin ? ' (admin)' : ''}`)

      const session: Session = { uid, email, firUser: user, admin, loopableAdmin }

      setAuthStatus('signedIn')
      setSession(session)
    }

    void getClaims()
  }, [user, org])

  // Initialize app
  useMountEffect(() => {
    // Initialize Firebase
    const firApp =  initializeApp(firebaseConfig, firebaseAppName)

    const auth = getAuth(firApp)
    const db = getFirestore(firApp)

    setAuth(auth);
    setDb(db);
    setFirApp(firApp)

    // Watch changed auth status
    const unsubscribeAuthState = onAuthStateChanged(auth,
      (firUser: User | null) => {
        setUser(firUser)
      }
    )

    // Fetch org based on domain

    const isDev = (projectEnv === 'development')
    const overrideAppDomain = isDev ? process.env.NEXT_PUBLIC_APP_DOMAIN : undefined;
    const appDomain = overrideAppDomain || canonicalAppDomain(window.location.hostname);

    const fetchOrg = async () => {
      const orgsRef = collection(db, 'organizationsPublic')
      const q = query(orgsRef, where('appDomain', '==', appDomain), limit(1));
      const querySnap = await getDocs(q);

      if (querySnap.empty) {
        return;
      }

      const org = querySnap.docs[0].data() as OrganizationPublic;
      setOrg(org)
    }
    void fetchOrg();

    return () => {
      console.log('Unsubscribing app', unsubscribeAuthState)
      unsubscribeAuthState()
    }
  })

  return [app, setApp]
}

export default useApp
