import '../styles/globals.css'
import type { AppProps } from 'next/app'

import Head from 'next/head'

import useApp from '../lib/hooks/app'

const MyApp = ({ Component, pageProps }: AppProps) => {
  const [app] = useApp()

  return (
    <>
      <Head>
        {app?.org.favicon ? Object.entries(app.org.favicon).map(([key, { url, width, height, mimeType }]) => (
          <link rel="icon" type={mimeType} sizes={`${width}x${height}`} href={url} key={key} />
        )) : null}
      </Head>
      <Component {...pageProps} app={app} />
    </>
  )
}

export { reportWebVitals } from 'next-axiom'

export default MyApp
